import React, { useEffect, useState } from 'react'
import './searchResults.scss'
import { pushDataLayerEvent } from '../../../shared/utils/metricManagementUtil'

const SearchResults = ({ resultsQuery, searchtext }) => {
	const locale = process.env.LOCALE

	const [results, setresults] = useState([])
	const dataLayerSelect = (text, total, indexElement) => {
		pushDataLayerEvent({
			event: 'select_search_result',
			search_term: text,
			search_results_count: total,
			index: indexElement
		})
	}

	useEffect(() => {
		setresults(
			resultsQuery.edges.filter(info => {
				if (info.node.context != null) {
					if (
						info.node.context.metaTitulo !== null &&
						locale === info.node.context.language
					) {
						return (
							info.node.context.metaTitulo
								.normalize('NFD')
								.replace(/[\u0300-\u036f]/g, '')
								.toLowerCase()
								.indexOf(
									searchtext
										.replace(/%20/g, ' ')
										.normalize('NFD')
										.replace(/[\u0300-\u036f]/g, '')
										.toLowerCase()
										.trim()
								) > -1
						)
					}
				}
				return false
			})
		)
	}, [])

	return (
		<div
			aria-label='Search results for all posts'
			className='search-results-list-content'
		>
			<h2 className='search-results-list-content-title'>
				RESULTADOS DE BÚSQUEDA
			</h2>
			{results.length <= 0 && (
				<p className='search-results-list-content-ppl'>
					No se encontraron resultados para: <span>{searchtext}</span>
				</p>
			)}

			{results.length > 0 && (
				<>
					<p className='search-results-list-content-ppl'>
						Tu búsqueda de <span>{searchtext}</span> ha arrojado{' '}
						<span>{results.length}</span> resultados
					</p>
					<ol className='search-results-list'>
						{results.map((item, index) => {
							let link = item.node.context.slug
							if (!link.startsWith('/')) {
								link = '/' + link
							}

							return (
								<li key={index}>
									<a className='search-results-list__heading-a' href={link}
										onClick={() => dataLayerSelect(searchtext, results.length, index)}
									>
										<h3 className='search-results-list__heading'>
											{item.node.context.metaTitulo}
										</h3>
									</a>
									<p>{item.node.context.metaDescripcion.metaDescripcion}</p>
								</li>
							)
						})}
					</ol>
					<br />
				</>
			)}
		</div>
	)
}

export default SearchResults
