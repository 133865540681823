import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import SearchResults from '../components/molecules/searchResults/searchResults'
import Layout from '../shared/components/organisms/layout/layout'
import SEO from '../shared/components/seo'
import useMobileMode from '../shared/hooks/useMobileMode'

const Resultados = props => {
	const query = useStaticQuery(
		graphql`
			query MyQuery {
				allSitePage {
					edges {
						node {
							path
							context {
								slug
								titulo
								metaTitulo
								metaDescripcion {
									metaDescripcion
								}
								language
							}
						}
					}
				}
				allContentfulTenaSeccionBeneficios(filter: { slug: { ne: "null" } }) {
					edges {
						node {
							slug
							titulo
							metaTitulo
							metaDescripcion {
								metaDescripcion
							}
							node_locale
						}
					}
				}
				allContentfulTenaProductos {
					edges {
						node {
							metaTitulo
							perteneceAEstePais
							metaDescripcion {
								metaDescripcion
							}
							slug
							node_locale
						}
					}
				}
				allContentfulTenaRecomendador {
					edges {
						node {
							metaTitulo
							metaDescripcion {
								metaDescripcion
							}
							slug
							node_locale
						}
					}
				}
				allContentfulTenaArticulos(
					filter: { perteneceAEstePais: { ne: false } }
				) {
					edges {
						node {
							metaTitulo
							metaDescripcion {
								metaDescripcion
							}
							slug
							node_locale
						}
					}
				}
				allContentfulTenaEventos {
					edges {
						node {
							metaTitulo
							metaDescripcion {
								metaDescripcion
							}
							node_locale
							tipoDeEvento
							estadoWebinar
							slug
							referenciasDeConferencistasLecciones {
								slug
							}
						}
					}
				}
			}
		`
	)
	// //Query for webinar
	// allContentfulTenaWebinar {
	//   edges {
	//     node {
	//       titulo
	//       slug
	//       metaTitulo
	//       metaDescripcion {
	//         metaDescripcion
	//       }
	//       node_locale
	//       referenciasDeConferencistas {
	//         slug
	//       }
	//     }
	//   }
	// }

	const arrayGeneral = query.allSitePage.edges
	const arrayBenefits = query.allContentfulTenaSeccionBeneficios.edges
	const arrayProducts = query.allContentfulTenaProductos.edges.concat(
		query.allContentfulTenaRecomendador?.edges
	)
	const arrayArticles = query.allContentfulTenaArticulos.edges
	const arrayWebinar = query.allContentfulTenaEventos.edges

	const arraySearch = []
	arrayGeneral.forEach(info => {
		if (info.node.context != null && info.node.context.slug !== null) {
			const item = {
				node: {
					context: {
						slug: info.node.context.slug,
						titulo: info.node.context.titulo,
						metaTitulo: info.node.context.metaTitulo,
						metaDescripcion: info.node.context.metaDescripcion,
						language: info.node.context.language,
					},
				},
			}
			arraySearch.push(item)
		}
	})

	arrayBenefits.forEach(info => {
		if (info.node !== null && info.node.slug !== null) {
			const item = {
				node: {
					context: {
						slug: 'beneficios/' + info.node.slug,
						titulo: info.node.titulo,
						metaTitulo: info.node.metaTitulo,
						metaDescripcion: info.node.metaDescripcion,
						language: info.node.node_locale,
					},
				},
			}
			arraySearch.push(item)
		}
	})

	arrayArticles.forEach(info => {
		if (info.node !== null && info.node.slug !== null) {
			const item = {
				node: {
					context: {
						slug: 'academia-tena/articulos/' + info.node.slug,
						titulo: info.node.titulo,
						metaTitulo: info.node.metaTitulo,
						metaDescripcion: info.node.metaDescripcion,
						language: info.node.node_locale,
					},
				},
			}
			arraySearch.push(item)
		}
	})

	arrayProducts.forEach(info => {
		if (
			info.node !== null &&
			info.node.slug !== null &&
			info.node.perteneceAEstePais === true
		) {
			const item = {
				node: {
					context: {
						slug: 'productos/' + info.node.slug,
						titulo: info.node.titulo,
						metaTitulo: info.node.metaTitulo,
						metaDescripcion: info.node.metaDescripcion,
						language: info.node.node_locale,
					},
				},
			}
			arraySearch.push(item)
		}
	})

	arrayWebinar.forEach(info => {
		if (info.node !== null && info.node.slug !== null) {
			let firstSlug = ''
			let secondSlug = info.node.slug
			if (info.node.estadoWebinar !== 'Finalizado el Evento') {
				firstSlug = 'eventos/'
				secondSlug = info.node.slug
			} else if (info.node.tipoDeEvento === 'Curso') {
				firstSlug = 'cursos/'
			} else if (info.node.tipoDeEvento === 'Live') {
				firstSlug = 'lives/'
				secondSlug = info.node.slug
			} else {
				const conferenceSlug =
					info.node.referenciasDeConferencistasLecciones? info.node.referenciasDeConferencistasLecciones[0].slug ||'': ''
				firstSlug = 'eventos/'
				secondSlug =
					info.node.referenciasDeConferencistasLecciones?.length === 1
						? info.node.slug + conferenceSlug
						: info.node.slug
			}

			const item = {
				node: {
					context: {
						slug: 'academia-tena/' + firstSlug + secondSlug,
						titulo: info.node.titulo,
						metaTitulo: info.node.metaTitulo,
						metaDescripcion: info.node.metaDescripcion,
						language: info.node.node_locale,
					},
				},
			}
			arraySearch.push(item)
		}
	})

	const allSitePageConcat = {
		edges: arraySearch,
	}

	const searchtext = props.location.search.replace('?search=', '')

	const { isMobile, currentPage } = useMobileMode()

	return (
		<Layout title={'Resultados de busqueda TENA'} isMobile={isMobile} currentPage={currentPage}>
			<SEO
				title={'Resultados de busqueda TENA'}
				description={'Resultados de busqueda TENA'}
				lang={process.env.LOCALE}
			/>
			<SearchResults
				resultsQuery={allSitePageConcat}
				searchtext={decodeURIComponent(searchtext)}
			/>
		</Layout>
	)
}

export default Resultados
